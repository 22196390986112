<template>
  <div class="grey darken-4">
    <BannerHeader
      title="Concrete Pumping &amp; Conveying"
      subtitle="Because we must do more than meet your expectations"
      uuid="c9285fda-f7eb-4104-9e09-306d2d8cb16f"
      :height="$vuetify.breakpoint.mdAndDown ? '25vh' : '50vh'"
    />
    <ServicesContent
      class="mt-12"
      :images="images"
    >
      <h2 class="text-h5 grey--text text--lighten-3 text-uppercase font-weight-medium">
        Today’s technology and C&amp;C Industrial’s expertise has given concrete contractors a safer, and more cost-effective concrete placement method, previously unavailable.
      </h2>
      <v-divider class="primary my-4" />
      <div class="grey--text text--lighten-2">
        <p>Concrete pumping and finishing takes more than just showing up to the job-site with a machine to do the job; it demands a commitment to our employees, equipment, and most importantly, our customer’s needs.</p>
        <p>We utilize all our resources to execute innovative concrete pumping and placing techniques and creative management to develop workable solutions for even the most challenging job-sites, with a personalized care that is second to none.</p>
        <p>Many of our loyal customers come back to us time and time again for the kind of service they’ve come to expect. From day one on your job-site until the project’s completion, C&amp;C is here to help you pump, place, and profit.</p>
        <p>When you are looking for a company committed to safety, quality workmanship, ethics, and service excellence, look no further than C&amp;C Concrete Pumping… Because we must do more than meet your expectations.</p>
      </div>
      <template v-slot:sidebar>
        <h3
          id="expertise-list"
          class="text-h5 primary--text"
        >Available Pump Sizes</h3>
        <v-list
          class="transparent"
          dense
        >
          <template v-for="(i, idx) in bullets">
            <v-hover v-slot="{ hover }" :key="idx">
              <v-list-item
                :key="idx"
                :class="`text-overline ${hover && i.url ? 'primary cursor' : ''}`"
                :href="i.url ? i.url : ''"
                :target="i.targetBlank ? '_blank' : ''"
              >
                <v-icon
                  v-if="i.icon"
                  :color="hover ? 'white' : i.iconColor ? i.iconColor : ''"
                  left
                >
                  {{ i.icon }}
                </v-icon>
                <span :class="hover && i.url ? 'white--text' :'grey--text text--lighten-2'">
                  {{ i.value }}
                </span>
              </v-list-item>
            </v-hover>
            <v-divider
              v-if="idx < bullets.length - 1"
              :key="`${idx}-divider`"
               class="grey darken-3"
            />
          </template>
        </v-list>
      </template>
    </ServicesContent>
    <v-divider />
  </div>
</template>

<script>
import BannerHeader from '@/core/components/BannerHeader'
import ServicesContent from '@/modules/services/components/ServicesContent'
export default {
  metaInfo: {
    title: 'Pumping & Conveying',
    description: 'Concrete pumping and finishing takes more than just showing up to the job-site with a machine to do the job; it demands a commitment to our employees, equipment, and most importantly, our customer’s needs.'
  },
  components: {
    BannerHeader,
    ServicesContent

  },
  data () {
    return {
      bullets: [
        {
          value: '38 Meter Z Boom',
          url: 'https://ucarecdn.com/20df1456-e18e-4101-a943-3f7a124e7ba2/CCIndustrialPumpSpecs38Z5.pdf',
          targetBlank: true,
          icon: 'fa-regular fa-file-pdf',
          iconColor: 'primary'
        },
        {
          value: '39 Meter 5 section Z Boom',
          url: 'https://ucarecdn.com/8599b51b-52ad-4bb8-a417-8d89bcd24a7f/CCIndustrialPumpSpecs39ZMeter.pdf',
          targetBlank: true,
          icon: 'fa-regular fa-file-pdf',
          iconColor: 'primary'
        },
        {
          value: '47 Meter Z Boom',
          url: 'https://ucarecdn.com/fab60183-05be-41f9-983c-9a769a4c129c/CCIndustrialPumpSpecs47mZ.pdf',
          targetBlank: true,
          icon: 'fa-regular fa-file-pdf',
          iconColor: 'primary'
        },
        {
          value: 'Telebelt TB 110',
          url: 'https://ucarecdn.com/4ce6c9ae-503a-45a6-bffb-ef61afbea285/CCIndustrialPumpSpecsTB_110.pdf',
          targetBlank: true,
          icon: 'fa-regular fa-file-pdf',
          iconColor: 'primary'
        },
        {
          value: 'Line Pump'
        }
      ],
      images: [
        {
          uuid: 'a8c0503b-1b77-4b27-b3ee-6592950746fa',
          alt: 'Concrete Pumping Boom'
        },
        {
          uuid: 'be451b7f-51c4-452a-af90-a99e7d0ca9ad',
          alt: 'Concrete Pumping Boom'
        },
        {
          uuid: '81a21823-aa4a-4e8a-9709-634e520f5ed7',
          alt: 'Concrete Pumping Boom'
        },
        {
          uuid: 'e8b37fe6-8cf3-481a-a868-55266e0b4379',
          alt: 'Concrete Pumping Boom'
        },
        {
          uuid: 'c573a468-490b-4d09-bde1-2d141f275a0a',
          alt: 'Concrete Pumping Boom'
        },
        {
          uuid: 'e63f0ed1-db16-41c1-913c-aeb798aa6dd2',
          alt: 'Telebelt Concrete Placement'
        },
        {
          uuid: '63224f2b-2828-4e01-8345-40d5cc51e466',
          alt: 'Concete Pumping Boom',
          mode: 'portrait'
        },
        {
          uuid: '75ec98ba-b398-429b-990f-1fea6a631713',
          alt: 'Concete Pumping Boom',
          mode: 'portrait'
        },
        {
          uuid: 'f77458ee-a498-4194-bedf-3ea11c8322b6',
          alt: 'Line Dragon - University Edge'
        },
        {
          uuid: '05c216af-762f-4ce1-ba80-7771a817f003',
          alt: 'Line Dragon - Live Oak Bank'
        },
        // Hide 2021-03-02 per Alex as the vertical is not cropping as preffered in the preview thumbnail
        // {
        //   uuid: 'b042547d-ae79-471e-acab-23f45c30f08c',
        //   alt: 'TA Loving Crane',
        //   mode: 'portrait'
        // },
        {
          uuid: '903c0df8-819e-43ab-90fd-e103a19bc610',
          alt: 'Concrete Pumping Boom'
        },
        {
          uuid: 'e63b99cd-6af2-4d04-a6b1-f652f8eaa9cb',
          alt: 'Concrete Pumping Boom'
        }
      ]
    }
  }
}
</script>
